body, html {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000; /* Black Background */
  color: #fff; /* White Text */
  height: 100%;
  overflow-x: hidden;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 5%;
  border-bottom: 1px solid #fff;
}

.navbar h1 {
  font-size: 1.5rem;
}

.navbar a {
  margin-left: 20px;
  text-decoration: none;
  color: #fff;
  padding: 5px 10px;
  border: 1px solid transparent;
  transition: border 0.3s, background-color 0.3s;
}

.navbar a:hover {
  border: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.hero {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  text-align: center;
}

.hero h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto;
}

.footer {
  padding: 1rem 5%;
  text-align: center;
  border-top: 1px solid #fff;
}
